import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Head/Head"
import { graphql } from "gatsby"
import * as CoreValueStyles from "./CoreValuePage.module.scss"
import RichTextRenderer from "../components/RichTextRenderer/RichTextRenderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const query = graphql`
  query {
    contentfulCoreValuePage {
      title
      coreImage {
        description
        gatsbyImageData(placeholder: BLURRED)
      }
      content {
        raw
        __typename
        references {
          contentful_id
          title
          content {
            content
          }
          __typename
        }
      }
    }
  }
`

const OurValuesPage = ({ data }) => {
  const { title, coreImage, content } = data.contentfulCoreValuePage
  const image = getImage(coreImage)

  return (
    <Layout backgroundColor={"#000"}>
      <Head title={title}></Head>
      <div className={CoreValueStyles.container}>
        <div>
          <GatsbyImage image={image} alt={coreImage.description} />
        </div>
        <div>
          <h1>{title}</h1>
          <RichTextRenderer content={content} />
        </div>
      </div>
    </Layout>
  )
}

export default OurValuesPage
